@import url(https://fonts.googleapis.com/css?family=Raleway:300,700);

#not-found {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
	display: flex;
	justify-content: center;
	align-items: center;
}

#not-found > div {
    text-align: center;
}

#not-found > div p {
    font-size: 240px;
    color: #4bc95c;
    z-index: 9;
}

#not-found > div p small {
    font-size: 40px;
    line-height: 100%;
    vertical-align: top;
}
